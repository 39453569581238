import React from "react";
import { Col } from "react-bootstrap";
import ENews from "./ENews";
import MediaContacts from "./MediaContacts";
import FacebookFeed from "./FacebookFeed";
import Resources from "./Resources";

const Sidebar = () => {
  return (
    <Col>
      <ENews />
      <MediaContacts
        contacts={[
          {
            //firstName: "Firstname",
            //lastName: "Lastname",
            //jobTitle: "Job Title",
            message: "For all general media inquiries:",
            phone: "972-442-5405",
            email: "publicrelations.info@ntmwd.com",
          },
        ]}
      />{" "}
      <Resources
        links={[
          { title: "All News Releases", to: "https://www.ntmwd.com/news-releases" },
          { title: "Photos & Video", to: "/photos-videos" },
          { title: "Events Calendar", to: "https://www.ntmwd.com/events" },
          {
            title: "Social Media",
            to: "https://www.ntmwd.com/social-media/",
            target: "self",
          },
        ]}
      />
      <FacebookFeed />
    </Col>
  );
};

export default Sidebar;
