import React from "react";
import Layout from "../components/layout/MainLayout";
import { graphql } from "gatsby";
import { Container, Row } from "react-bootstrap";
import { Crumbs } from "../components/common/ntmwd/elements/Crumbs";
import Sidebar from "../components/routes/Home/News/Sidebar/Sidebar";
import ModernPost from "../components/routes/Home/News/MainColumns/ModernFeed/ModernPost";
import moment from "moment";
import { SEO } from "wmk-lib";
import {
  siteTitle,
  stopGapRelativeToAbsolutePaths,
  subdomainUrl,
  twitter,
} from "../vars/helpers";

export default ({ pageContext, data }) => {
  const { post } = data;
  const { title, feat, content, slug, tags, date } = post;
  return (
    <Layout>
      <SEO
        baseUrl={subdomainUrl}
        title={title}
        siteTitle={siteTitle + " News Room"}
        slug={slug}
        twitterHandle={twitter}
        twitterImage={null}
        ogImage={null}
      />
      <Container>
        <Row>
          <Crumbs
            breadcrumbs={[
              { title: "Home", to: "https://ntmwd.com/", target: "self" },
              { title: "News Room", to: "/" },
              { title, to: "/" + slug },
            ]}
          />
        </Row>
        <Row>
          <ModernPost
            title={title}
            feat={feat}
            content={stopGapRelativeToAbsolutePaths(content)}
            tags={tags}
            allTags
            date={moment(date)}
          />
          <Sidebar />
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query postQuery($slug: String!) {
    post: wpPost(slug: { eq: $slug }) {
      ...NodePostFields
    }
  }
`;
