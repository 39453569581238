import React from "react";
import { Row, Col } from "react-bootstrap";
import { WMKLink } from "wmk-lib";
import styled from "styled-components";
import { StyledSidebarContainer, StyledDetails, StyledSidebarHeader } from ".";
import { colors } from "../../../../../vars/palette";

const Styled = {};
Styled.FirstName = styled(Col)`
  color: ${colors.hex("primary")};
  font-weight: 400;
  font-size: 1.125rem;
  text-align: center;
`;

const MediaContacts = ({ contacts }) => {
  return (
    <StyledSidebarContainer className="flex-column">
      <Col>
        <StyledSidebarHeader>Media Contacts</StyledSidebarHeader>
      </Col>
      {contacts.map((contact) => {
        const { message, email, phone } = contact;
        return (
          <Col key={email + phone}>
            <Row className="flex-column" style={{ justifyContent: "center" }}>
              <Styled.FirstName>{message}</Styled.FirstName>
              <StyledDetails center="true">
                <WMKLink tel>{phone}</WMKLink>
              </StyledDetails>
              <StyledDetails center="true">
                <WMKLink mailto>{email}</WMKLink>
              </StyledDetails>
              <StyledDetails center="true">
                <WMKLink
                  to="https://www.ntmwd.com/media-contacts/"
                  target="self"
                >
                  More Info
                </WMKLink>
              </StyledDetails>
            </Row>
          </Col>
        );
      })}
    </StyledSidebarContainer>
  );
};

export default MediaContacts;
