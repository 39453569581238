import React from "react";
import { StyledSidebarHeader, StyledSidebarContainer } from ".";

const FacebookFeed = () => {
  return (
    <StyledSidebarContainer>
      <StyledSidebarHeader>Facebook Feed</StyledSidebarHeader>
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<div class="powr-social-feed" id="ec8fe49a_1599244950"></div><script src="https://www.powr.io/powr.js?platform=react"></script>',
        }}
      />
    </StyledSidebarContainer>
  );
};

export default FacebookFeed;
