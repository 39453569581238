import React from "react";
import { Row, Col } from "react-bootstrap";
import Img from "gatsby-image";
import { Heading1 } from "../../../../../common/ntmwd";
import ModernTags from "./ModernTags";
import { Styled } from "./ModernStyled";
import { colors } from "../../../../../../vars/palette";
import styled from "styled-components";

const NewContent = styled(Row)`
  .alignleft {
    margin-right: 1.5em;
    display: inline;
    float: left;
  }
  .alignright {
    margin-left: 1.5em;
    display: inline;
    float: right;
  }
  h4,
  h4 > em {
    color: #005eb8;
  }
`;

export const ModernPostTitle = ({ children }) => {
  return (
    <Heading1 din style={{ color: colors.hex("primary"), fontSize: "1.75rem" }}>
      {children}
    </Heading1>
  );
};

const ModernPost = ({ title, feat, content, tags, date }) => {
  //const { fixed } = feat ? feat : { feat: null };
  const { localFile } = feat && feat.node ? feat.node : { localFile: null };
  const { childImageSharp } = localFile ? localFile : { childImageSharp: null };
  const { original } = childImageSharp ? childImageSharp : { original: null };
  const _date = date.format("MMMM D, YYYY");
  return (
    <Col xs={12} sm={12} md={7} lg={8}>
      <Styled.Card>
        <Row className="flex-column" style={{ padding: "0 2vh" }}>
          {" "}
          <ModernPostTitle>{title}</ModernPostTitle>
          <Row style={{ width: "100%" }}>
            <ModernTags tags={tags} all />
            <Styled.Date xs="auto">{_date}</Styled.Date>
          </Row>
          {original ? (
            <div style={{ width: "100%" }}>
              <Img
                fluid={original}
                alt={
                  feat.node.altText
                    ? feat.node.altText
                    : feat.node.localFile.name
                }
                style={{ marginTop: "1vh" }}
                objectFit="cover"
              />
            </div>
          ) : null}
        </Row>
      </Styled.Card>
      <NewContent className="news-content">
        {content ? (
          <Styled.Content
            dangerouslySetInnerHTML={{ __html: content }}
            style={{ padding: "4vh" }}
          />
        ) : null}
      </NewContent>
    </Col>
  );
};

export default ModernPost;
