import React from "react";
import { Button } from "../../../../common/ntmwd";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { StyledSidebarHeader } from ".";
import { colors } from "../../../../../vars/palette";

const Styled = {};
Styled.ENews = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.rgba("secondary", 0.125)};
  border-top: 8px solid ${colors.hex("tertiary")};
  border-bottom: 3px solid ${colors.hex("tertiary")};
  padding: 3vh 1vh;
  margin-bottom: 4vh;
`;

const ENews = () => {
  const { page } = useStaticQuery(query);
  return (
    <Styled.ENews>
      <StyledSidebarHeader>E-Newsletters</StyledSidebarHeader>
      <Button to={`/${page.slug}`}>Sign up for e-Newsletters</Button>
    </Styled.ENews>
  );
};

export default ENews;

const query = graphql`
  {
    page: wpPage(title: { eq: "Newsletters" }) {
      ...NodePageFields
    }
  }
`;
