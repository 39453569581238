import React from "react";
import PostTag from "../NewsFeed/PostTag";
import { Styled } from "./ModernStyled";
import get from "lodash/get";
import { slugify } from "../../../../../common/MediaGallery/util/tagging";

const ModernTags = ({ tags, all, featured }) => {
  const __tags = tags ? tags.nodes.map((t) => t.name) : [];
  const _tags = __tags.map(
    (tag) => tag[0].toUpperCase() + tag.slice(1, tag.length)
  );
  //const title = get(featured,'title') ? get(featured,'title') :
  const slug = get(featured, "slug");
  return _tags.length ? (
    all ? (
      <Styled.Tags>
        {_tags.map((tag, i) => {
          const sep = i < _tags.length - 1 ? ", " : "";
          return (
            <PostTag key={"tag" + i} path={"/tags/" + slugify(tag)}>
              {tag + sep}
            </PostTag>
          );
        })}
      </Styled.Tags>
    ) : (
      <Styled.Tags>
        <PostTag path={'/tags/'+slug}>
          {featured ? featured.title : _tags[0]}
        </PostTag>
      </Styled.Tags>
    )
  ) : null;
};

export default ModernTags;
