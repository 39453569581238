import React from "react";
import { Col } from "react-bootstrap";
import { Heading5 } from "../../../../common/ntmwd";
import { WMKLink } from "wmk-lib";
import { StyledSidebarContainer, StyledDetails } from ".";
import { colors } from "../../../../../vars/palette";

const Resources = ({ links }) => {
  return (
    <StyledSidebarContainer className="flex-column">
      <Col>
        <Heading5
          din
          style={{
            color: colors.hex("primary"),
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Additional Resources
        </Heading5>
      </Col>
      {links.map((link) => {
        const { title, to, target } = link;
        return (
          <StyledDetails key={title + to} center="true">
            <WMKLink to={to} target={target}>
              {title}
            </WMKLink>
          </StyledDetails>
        );
      })}
    </StyledSidebarContainer>
  );
};
export default Resources;
