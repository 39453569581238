import styled from "styled-components";
import { Col, Row, Card } from "react-bootstrap";
import { WMKLink } from "wmk-lib";
import { colors } from "../../../../../../vars/palette";

export const Styled = {};

Styled.Card = styled(Card)`
  padding: 2vh;
  h5 .wmk-link {
    color: ${colors.hex("primary")};
    :hover {
      color: ${colors.hex("secondary")};
      text-decoration: none;
    }
  }
`;

Styled.Content = styled(Col)`
  a {
    color: ${colors.hex("primary")};
    :hover {
      color: ${colors.hex("accent")};
    }
  }
  iframe {
    max-width: 100%;
    width: 100%;
    min-height: 50vh;
  }
  .wp-caption {
    max-width: 100%;
  }
`;

Styled.Date = styled(Col)`
  font-size: 0.7rem;
  color: ${colors.hex("text")};
  margin-bottom: 1vh;
`;

Styled.Excerpt = styled.div`
  font-size: 0.75rem;
  a {
    color: ${colors.hex("primary")};
  }
`;

Styled.HeadlineLink = styled(WMKLink)`
color: ${colors.hex("primary")};
transition: all .3s ease;
:hover {
  color: ${colors.hex("secondary")};
  text-decoration: none;
  transition: all .3s ease;
}
.din {
  padding-bottom: .75vh,
  margin-bottom: .325vh,
}
`;

Styled.LoadMoreButton = styled.button`
  background-color: ${colors.hex("primary")};
  padding: 0.7rem 1rem;
  border-radius: 2px;
  color: ${colors.hex("white")};
  transition: all 0.45s ease;
  appearance: none;
  border: none;
  :hover {
    text-decoration: none;
    background-color: ${colors.hex("accent")};
    transition: all 0.3s ease;
    color: ${colors.hex("white")};
  }
  .wmk-link {
    color: ${colors.hex("white")};
    :hover {
      text-decoration: none;
    }
  }
`;

Styled.Tags = styled(Col)`
  font-size: 0.7rem;
  font-weight: bold;
  .wmk-link {
    color: ${colors.hex("tertiary")};
  }
`;

Styled.Thumbnail = styled(Col)``;

Styled.Wrapper = styled(Row)`
  margin: 0.85vh 0;
  .text {
    padding-left: 1rem;
  }
  &.small {
    .text {
      padding-left: 0.5rem;
    }
  }
`;
