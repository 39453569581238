import React from "react";
import { WMKLink } from "wmk-lib";
import { slugify } from "../../../../../common/MediaGallery/util/tagging";

const PostTag = ({ children, path }) => {
  const to = path ? path : "/tags/" + slugify(children);
  //console.log(to, path);
  return <WMKLink to={to}>{children}</WMKLink>;
};

export default PostTag;
