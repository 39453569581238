import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import get from "lodash/get";
import { Row, Col } from "react-bootstrap";
import { WMKLink } from "wmk-lib";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";

const Styled = {};
Styled.Breadcrumbs = styled(Row)`
  padding: 0 2rem;
  font-size: .75rem;
  align-items: center;
  margin-bottom: 1vh;
  .col {
      display: flex;
      align-items: center;
  }
  .wmk-link {
      color: ${colors.hex("primary")};
  }
`;

const query = graphql`
  {
    file(name: { eq: "leaf" }) {
      childImageSharp {
        fixed(width: 10, height: 15, fit: FILL) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export const Crumbs = ({ breadcrumbs }) => {
  const { file } = useStaticQuery(query);
  const fixed = get(file, "childImageSharp.fixed");
  const Leaf = () => (fixed ? <Img fixed={fixed} alt="" /> : null);
  return (
    <Styled.Breadcrumbs>
      {breadcrumbs.map((crumb, i) => {
        const { title, to, target } = crumb;
        return (
          <React.Fragment key={title + i}>
            <Crumb  to={to} target={target}>
              {title}
            </Crumb>
            {i < breadcrumbs.length - 1 ? (
              <Col>
                <Leaf />
              </Col>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      })}
    </Styled.Breadcrumbs>
  );
};

const Crumb = ({ children, to, target }) => {
  return (
    <WMKLink to={to} target={target}>
      {children}
    </WMKLink>
  );
};
