import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Heading5 } from "../../../../common/ntmwd";
import React from "react";
import { colors } from "../../../../../vars/palette";

export const StyledSidebarContainer = styled(Row)`
  padding: 0 0.25vw 1vw 0.25vw;
  margin: 3vh 0;
`;

export const StyledDetails = styled(Col)`
  color: ${colors.hex("tertiary")};
  font-weight: 400;
  .wmk-link {
    color: ${colors.hex("tertiary")};
  }
  text-align: ${({ center }) => (center === "true" ? "center" : "left")};
`;

export const StyledSidebarHeader = ({ children }) => (
  <Heading5
    din
    style={{
      color: colors.hex("primary"),
      textTransform: "uppercase",
      textAlign: "center",
      width: "100%",
    }}
  >
    {children}
  </Heading5>
);
